import CardAuth from '.';

import Button from '../Button';
import ImageC from '../Image';
import Spinner from '../Spinner';

import classes from './index.module.less';

interface Props {
	onSignupWhatsappClick?: () => void;
	onSignupTelegramClick?: () => void;
	onCreateAccountClick?: () => void;
	onLoginClick?: () => void;
	onSuccess?: () => void;
	updateLoadingState?: (val: boolean) => void;
}

const CardSignUp = ({
	onSignupWhatsappClick,
	onSignupTelegramClick,
	onCreateAccountClick,
	onLoginClick,
}: Props) => {
	const handleSignupEmailClick = () => {
		onCreateAccountClick?.();
	};

	const handleSignupTelegramClick = () => {
		onSignupTelegramClick?.();
	};

	const handleSignupWhatsappClick = () => {
		onSignupWhatsappClick?.();
	};

	return (
		<Spinner spinning={false}>
			<CardAuth>
				<h1 className={classes.title}>Welcome</h1>
				<h2 className={classes.description}>Sign up to Creo Play </h2>
				<div className={classes['login-options']}>
					<Button
						className={classes['login-opt-button']}
						onClick={() => handleSignupWhatsappClick()}
					>
						<ImageC alt="icon wallet" src="/icons/whatsapp.svg" height={20} width={20} />
						<span>Sign Up via Whatsapp</span>
					</Button>
					<Button
						className={classes['login-opt-button']}
						onClick={() => handleSignupTelegramClick()}
					>
						<ImageC alt="icon wallet" src="/icons/telegram.svg" height={20} width={20} />
						<span>Sign Up via Telegram</span>
					</Button>
					<Button className={classes['login-opt-button']} onClick={() => handleSignupEmailClick()}>
						<ImageC alt="icon wallet" src="/icons/mail.svg" height={20} width={20} />
						<span>Sign Up via Email</span>
					</Button>
				</div>
				<div className={classes['create-account']}>
					<span>Already have an account?</span>
					<a onClick={() => onLoginClick?.()}>Log in</a>
				</div>
			</CardAuth>
		</Spinner>
	);
};

export default CardSignUp;
