import { useEffect, useRef, useState } from 'react';
import CardAuth from '.';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import classNames from 'classnames';

import { useLoginEmail, useResendOtpWhatsapp, useVerifyOtpWhatsapp } from 'src/hooks';
import useAuthReducer from 'src/hooks/reducers/useAuthReducer';
import { notify } from 'src/utils/notify';

import Button from '../Button';
import ImageC from '../Image';
import Input from '../Input';
import InputOTP from '../InputOTP';

import classes from './index.module.less';

interface Props {
	onForgotPasswordClick?: () => void;
	onClose?: () => void;
	onSuccess?: () => void;
	updateLoadingState?: (val: boolean) => void;
}

const CardLoginEmail = ({
	// onForgotPasswordClick,
	onClose,
	onSuccess,
	updateLoadingState,
}: Props) => {
	const { setUser } = useAuthReducer();

	const { mutate: loginEmail, data: dataLogin, isLoading: isLoadingLoginEmail } = useLoginEmail();
	const { mutate: verifyOtp, isLoading: isLoadingVerifyOtp } = useVerifyOtpWhatsapp();
	const { mutate: resendOtp, data: dataResendOtp } = useResendOtpWhatsapp();

	const resLogin = dataLogin?.data?.data;
	const resResendOtp = dataResendOtp?.data?.data;

	const isLoading = isLoadingLoginEmail || isLoadingVerifyOtp;

	const [formData, setFormData] = useState<any>(null);

	const otpNumberOfDigits = 6;
	const otpExpiryTime = parseInt(process.env.NEXT_PUBLIC_OTP_EXPIRED_DURATION as string); // in seconds

	const [step, setStep] = useState<number>(1);
	const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
	const [clipboardText, setClipboardText] = useState<string>('');

	const [otp, setOtp] = useState(new Array(otpNumberOfDigits).fill(''));
	const [otpExpiredCountdown, setOtpExpiredCountdown] = useState<number>(0);

	const finalOtp = otp.join('');

	const otpExpiredCountdownRef = useRef(otpExpiryTime);

	let otpExpiredCountdownInterval: any = null;
	const stopOtpExpiredCountdown = () => {
		if (otpExpiredCountdownInterval !== null) {
			clearInterval(otpExpiredCountdownInterval);
			otpExpiredCountdownInterval = null;
		}
	};
	const startOtpExpiredCountdown = () => {
		stopOtpExpiredCountdown();

		otpExpiredCountdownRef.current = otpExpiryTime;
		setOtpExpiredCountdown(otpExpiredCountdownRef.current);

		otpExpiredCountdownInterval = setInterval(() => {
			if (otpExpiredCountdownRef.current === 0) {
				stopOtpExpiredCountdown();
				return;
			}
			otpExpiredCountdownRef.current = otpExpiredCountdownRef.current - 1;
			setOtpExpiredCountdown(otpExpiredCountdownRef.current);
		}, 1000);
	};

	const getCountdownTimer = () => {
		const min: string = Math.floor(otpExpiredCountdown / 60).toString();
		let sec: string = (otpExpiredCountdown % 60).toString();
		if (sec.length === 1) {
			sec = '0' + sec;
		}
		return min + ':' + sec;
	};

	const getClipboardText = async () => {
		let text = (await navigator.clipboard.readText()) ?? '';
		text = text.replace(/\s/g, '');
		setClipboardText(text);
	};

	let getClipboardTextInterval: any = null;
	const startGetClipboardTextInterval = () => {
		getClipboardText();

		if (getClipboardTextInterval === null) {
			getClipboardTextInterval = setInterval(() => {
				getClipboardText();
			}, 1000);
		}
	};
	const stopGetClipboardTextInterval = () => {
		clearInterval(getClipboardTextInterval);
		getClipboardTextInterval = null;
	};

	const formRef = useRef(null);

	const isButtonLoginDisabled =
		formData == null ||
		Object.values(formData ?? [])?.find((val: any) => val.length < 3) !== undefined;

	const handlePasteFromClickboardClick = () => {
		setOtp([
			clipboardText[0] ?? '',
			clipboardText[1] ?? '',
			clipboardText[2] ?? '',
			clipboardText[3] ?? '',
			clipboardText[4] ?? '',
			clipboardText[5] ?? '',
		]);
	};

	const handleFormChange = () => {
		const newFormData = new FormData(formRef.current as any);
		const formProps = Object.fromEntries(newFormData);

		setFormData(formProps);
	};

	const handleLoginEmailClick = (e: any) => {
		e.preventDefault();

		loginEmail(
			{
				email: formData.email,
				password: formData.password,
			},
			{
				onSuccess: (res: any) => {
					if (res.status === 200 && res?.data?.data) {
						setUser(res.data.data);
						notify('Successfully logged in');
						onSuccess?.();
					} else {
						notify(res?.data?.message ?? 'Failed to login', 'error');
					}
				},
			},
		);
	};

	const handleResendOtpClick = () => {
		const verifyToken = resResendOtp?.verify_token ?? resLogin?.verify_token;

		resendOtp(
			{ verify_token: verifyToken, creo_id: resLogin.creo_id },
			{
				onSuccess: (res: any) => {
					if (res.status === 200) {
						startOtpExpiredCountdown();
					} else {
						notify(res?.data?.message ?? 'Failed to resend otp', 'error');
					}
				},
			},
		);
	};

	const handleVerifyOtpClick = (e: any) => {
		e.preventDefault();

		if (finalOtp.length === otpNumberOfDigits) {
			const verifyToken = resResendOtp?.verify_token ?? resLogin?.verify_token;

			verifyOtp(
				{ verify_token: verifyToken, creo_id: resLogin.creo_id, otp: finalOtp },
				{
					onSuccess: (res: any) => {
						if (res.status === 200) {
							setUser({ ...res.data.data, xellar: true });
							notify('Login whatsapp success.');
							// update auth data here
							onSuccess?.();
						} else {
							notify(res?.data?.message ?? 'Failed to login whatsapp', 'error');
						}
					},
				},
			);
		}
	};

	useEffect(() => {
		updateLoadingState?.(isLoadingLoginEmail);
	}, [isLoadingLoginEmail]);

	useEffect(() => {
		startGetClipboardTextInterval();
		return () => {
			stopGetClipboardTextInterval();
		};
	}, []);

	useEffect(() => {
		setButtonDisabled(true);
		setOtp(new Array(otpNumberOfDigits).fill(''));
	}, [step]);

	useEffect(() => {
		if (finalOtp.length === otpNumberOfDigits) {
			setButtonDisabled(false);
		} else {
			setButtonDisabled(true);
		}
	}, [finalOtp]);

	return (
		<CardAuth>
			<div className={classes.header}>
				<div>
					{step > 1 && (
						<ImageC
							alt="icon back"
							className="cursor-pointer"
							src="/icons/icon-back-white.svg"
							width={16}
							height={16}
							onClick={() => setStep(step - 1)}
						/>
					)}
				</div>
				<div>
					<ImageC
						alt="icon close"
						className="ml-auto cursor-pointer"
						src="/icons/icon-close-white.svg"
						width={16}
						height={16}
						onClick={onClose}
					/>
				</div>
			</div>

			{step === 1 && <h1 className={classes.title}>Continue with Email</h1>}
			{step === 2 && <h1 className={classes.title}>Enter code</h1>}

			{step === 1 && (
				<>
					<form
						ref={formRef}
						className={classNames(classes.form, 'mt-3')}
						onChange={handleFormChange}
						onSubmit={handleLoginEmailClick}
					>
						<Row gutter={[30, 12]}>
							<Col span={24}>
								<Input type="email" name="email" placeholder="Email" />
							</Col>
							{/* <Col span={24}>
								<Input type="password" name="password" placeholder="Password" />
							</Col> */}
							<Col span={24}>
								<Button
									style={{ marginTop: 229 }}
									className="w-100"
									type="submit"
									loading={isLoadingLoginEmail}
									disabled={isButtonLoginDisabled}
								>
									Continue
								</Button>
							</Col>
						</Row>
					</form>
					{/* <a className={classes['forgot-password']} onClick={() => onForgotPasswordClick?.()}>
				Forgot password?
			</a> */}

					<div className={classNames(classes.agreement, 'mt-1')}>
						By signing up you are agreeing to <br />
						our <a>Terms of Use</a> and our <a>Privacy Policy</a>
					</div>
				</>
			)}

			{step === 2 && (
				<form
					className={classNames(classes.form, classes['form-otp'], 'mt-3')}
					onSubmit={handleVerifyOtpClick}
				>
					<InputOTP otp={otp} setOtp={setOtp} />

					<div className={classNames(classes.label, 'mt-3')}>
						<div>
							A one time verification code has been sent to <br />
							{formData?.email}
						</div>
					</div>
					{finalOtp.length === otpNumberOfDigits ? (
						<Button
							style={{ marginTop: 202 }}
							className={classNames('w-100', classes['submit-btn'], 'mb-4')}
							type="submit"
							loading={isLoading}
							disabled={buttonDisabled}
						>
							Continue
						</Button>
					) : (
						<Button
							style={{ marginTop: 202 }}
							className={classNames('w-100', classes['submit-btn'], 'mb-4')}
							onClick={handlePasteFromClickboardClick}
							disabled={clipboardText.length === 0}
						>
							Paste from Clipboard
						</Button>
					)}

					<div className={classNames(classes.label, 'fw-400')}>
						<div>
							Expires in <span className="fw-500">{getCountdownTimer()}</span>
						</div>
						<a
							className={classNames({ disabled: otpExpiredCountdown !== 0 })}
							onClick={handleResendOtpClick}
						>
							Resend code
						</a>
					</div>
				</form>
			)}
		</CardAuth>
	);
};

export default CardLoginEmail;
