import { useEffect, useState } from 'react';
import CardAuth from '.';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import classNames from 'classnames';

import { useRegisterTelegram, useVerifyOtpWhatsapp } from 'src/hooks';
import useCopyToClipboard from 'src/hooks/useCopyToClipboard';
import { notify } from 'src/utils/notify';

import Button from '../Button';
import Checkbox from '../Checkbox';
import ImageC from '../Image';
import Input from '../Input';
import Spinner from '../Spinner';

import classes from './index.module.less';

interface Props {
	onRecoverClick?: () => void;
	onClose?: () => void;
	onSuccess?: () => void;
	updateLoadingState?: (val: boolean) => void;
}

const CardRegisterTelegram = ({
	onRecoverClick,
	onClose,
	onSuccess,
	updateLoadingState,
}: Props) => {
	const { copy } = useCopyToClipboard();
	// const { setUser } = useAuthReducer();

	const {
		mutate: register,
		// data: dataRegister,
		isLoading: isLoadingRegister,
	} = useRegisterTelegram();

	const {
		// mutate: verifyOtp,
		data: dataVerifyOtp,
		// isLoading: isLoadingVerifyOtp,
	} = useVerifyOtpWhatsapp();
	const resVerifyOtp = dataVerifyOtp?.data?.data;

	const isLoading = isLoadingRegister;

	const [step, setStep] = useState<number>(1);
	const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

	const [formData, setFormData] = useState<{
		code: string;
		number: string;
	}>({
		code: '+62',
		number: '',
	});

	const [checked, setChecked] = useState<boolean>(false);

	const validateFormData = (data: any) => {
		if (
			data == null ||
			Object.values(data ?? [])?.find((val: any) => val.length < 3) !== undefined
		) {
			return false;
		}

		return true;
	};

	const handleFormInputChange = (e: any) => {
		const name = e.target.name;
		let value = e.target.value;
		// if (name === 'username') {
		// value = value.replace(/[^0-9a-z]/gi, '');
		// }

		if (name === 'code') {
			if (value.length > 5) {
				return;
			} else {
				// Removing every non-digit character except a plus (+) sign
				value = value.replace(/[^\d\+]+/g, '').replace(/(.)\++/g, '$1');
			}
		} else if (name === 'number') {
			if (value.length > 15) {
				return;
			}
		}
		setFormData({ ...formData, [name]: value });
	};

	const handleConnectClick = (e: any) => {
		e.preventDefault();

		const phoneNumber = `${formData.code}${formData.number}`.replaceAll('+', '');

		register(
			{ phone_number: phoneNumber },
			{
				onSuccess: (res: any) => {
					if (res.status === 200) {
						// start checking if user has confirm in telegram
						setStep(2);
					} else {
						notify(res?.data?.message ?? 'Failed to register whatsapp', 'error');
					}
				},
			},
		);
	};

	useEffect(() => {
		updateLoadingState?.(isLoading);
	}, [isLoading]);

	useEffect(() => {
		setButtonDisabled(true);
	}, [step]);

	useEffect(() => {
		if (validateFormData(formData)) {
			setButtonDisabled(false);
		} else {
			setButtonDisabled(true);
		}
	}, [formData]);

	return (
		<CardAuth showCreoplayImage={step === 3 ? false : true}>
			<div className={classes.header}>
				<div>
					{step > 1 && step < 3 && (
						<ImageC
							alt="icon back"
							className="cursor-pointer"
							src="/icons/icon-back-white.svg"
							width={16}
							height={16}
							onClick={() => setStep(step - 1)}
						/>
					)}
				</div>
				<div>
					<ImageC
						alt="icon close"
						className="cursor-pointer"
						src="/icons/icon-close-white.svg"
						width={16}
						height={16}
						onClick={onClose}
					/>
				</div>
			</div>

			{step === 1 && <h1 className={classes.title}>Sign up with Telegram</h1>}
			{step === 2 && <h1 className={classes.title}>Telegram Sign Up</h1>}
			{step === 3 && <h1 className={classes.title}>Register with Telegram</h1>}

			{step === 1 && (
				<>
					<form className={classNames(classes.form, 'pt-4 mb-0')} onSubmit={handleConnectClick}>
						<Row gutter={[30, 12]}>
							<Col span={24}>
								<div className={classes['phone-number']}>
									<div
										className={classes.code}
										style={{
											width: `calc(${formData?.code?.toString()?.length ?? 0}ch + 38px)`,
										}}
									>
										<Input
											name="code"
											placeholder="+62"
											// defaultValue={'+62'}
											value={formData.code}
											onChange={handleFormInputChange}
										/>
									</div>
									<div className={classes.line}></div>
									<div className={classes.number}>
										<Input
											className={classes.number}
											type="number"
											name="number"
											placeholder=""
											value={formData.number}
											onChange={handleFormInputChange}
										/>
									</div>
								</div>
							</Col>
						</Row>
						<div className={classNames(classes['create-account'], 'mt-4 text-center')}>
							Enter your Telegram number above and we will send you the verification number
						</div>
						<Button
							className={classNames(classes['submit-btn'], 'w-100 mb-4')}
							type="submit"
							loading={isLoading}
							disabled={buttonDisabled}
							style={{ marginTop: 145 }}
						>
							Join Creoplay
						</Button>
						<div className={classNames(classes['create-account'], 'mt-0')}>
							<span>Already have an account?</span>
							<a onClick={() => onRecoverClick?.()}>Log in</a>
						</div>
						<div className={classes.agreement}>
							By signing up you are agreeing to <br />
							our <a>Terms of Use</a> and our <a>Privacy Policy</a>
						</div>
					</form>
				</>
			)}

			{step === 2 && (
				<form
					className={classNames(classes.form, classes['form-otp'], 'pt-2')}
					// onSubmit={handleVerifyOtpClick}
				>
					<div className={classNames(classes.label)}>
						<div>
							Waiting for confirmation in Telegram <br />
							{formData.code + formData.number}
						</div>
					</div>
					<div className="w-100 mt-5 pt-5 d-flex justify-content-center">
						<Spinner className="" color="#FFFFFFCC" width={7} size={63} />
					</div>
				</form>
			)}

			{step === 3 && (
				<>
					<div className={classNames('w-100 d-flex flex-column align-items-center mt-5 pt-2')}>
						<ImageC src="/icons/icon-recovery-key.svg" width={88} height={88} />
						<span className="fs-12 fw-400 mt-4 mb-3 opacity-80">
							Please keep this recovery code below!
						</span>
						<div className="d-flex">
							<div
								className={classNames(classes['recovery-key'], 'mb-5')}
								onClick={() => copy(resVerifyOtp?.secret0)}
							>
								{resVerifyOtp?.secret0}
							</div>
							{/* <ButtonCopy clipboard={resVerifyOtp?.secret0} buttonType="light" /> */}
						</div>
						<Checkbox
							size={16}
							label={
								<span className="fs-12 fw-400 opacity-80">Yes I have saved the Recovery Code</span>
							}
							checkedIcon="check"
							checked={checked}
							onChange={(e) => setChecked(e.target.checked)}
						/>
						<Button
							className={classNames('w-100 mt-4', classes['submit-btn'])}
							disabled={!checked}
							onClick={onSuccess}
						>
							Enter the Creoplay
						</Button>
					</div>
				</>
			)}
		</CardAuth>
	);
};

export default CardRegisterTelegram;
